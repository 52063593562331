import { StepsStyleConfig as Steps } from 'chakra-ui-steps';

const theme = {
	colors: {
		pryRed: '#A60000',
		lineGray: '#BEBEBE',
		textGray: '#6C757D',
		bgGray: '#F4F4F4',
		warning: '#E96D6D',
		brandFbBlue: '#2374f2',
	},
	components: {
		Steps,
	},
	fonts: {
		heading: '"Outfit", "Noto Sans TC", sans-serif',
		body: '"Outfit", "Noto Sans TC", sans-serif',
	},
};

export default theme;
