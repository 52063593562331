import React from 'react';
import Head from 'next/head';
import Script from 'next/script';
import { SWRConfig } from 'swr';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { config } from '@fortawesome/fontawesome-svg-core';
// import { StepsStyleConfig as Steps } from 'chakra-ui-steps';
import myTheme from '../components/theme';
import 'bootstrap-css-only/css/bootstrap.min.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import 'leaflet/dist/leaflet.css';
import Provider from '../contexts/authContext';
import '../styles/globals.css';
import '../public/nprogress.css';
import { fetcher } from '../utils/axios';

config.autoAddCss = false;
const theme = extendTheme(myTheme);

function MyApp({ Component, pageProps }) {
	return (
		<Provider>
			<ChakraProvider theme={theme}>
				<Head>
					<link href="https://fonts.googleapis.com/css?family=Open+Sans&family=Noto+Sans+TC:100,400&display=swap" rel="stylesheet" />
				</Head>
				<Script
					strategy="lazyOnload"
					src="https://www.googletagmanager.com/gtag/js?id=G-8GYJ5NQHWY"
				/>
				<Script id="ga-analytics">
					{`
					window.dataLayer = window.dataLayer || [];
					function gtag(){dataLayer.push(arguments);}
					gtag('js', new Date());

					gtag('config', 'G-8GYJ5NQHWY');
					`}
				</Script>
				<SWRConfig value={{ fetcher }}>
					<Component {...pageProps} />
				</SWRConfig>
			</ChakraProvider>
		</Provider>
	);
}

export default MyApp;
