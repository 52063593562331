import React, { useState, useEffect, createContext } from 'react';
import dynamic from 'next/dynamic';
// import cookie from 'cookie-cutter';
// import jwt from 'jsonwebtoken';
import axios from 'axios';
// import * as Sentry from '@sentry/nextjs';

const ContextStore = createContext({});

const parseCookie = async () => {
	try {
		const { data } = await axios.get(`${process.env.NEXT_PUBLIC_AUTH_API_PATH}/api/v1/user/me`, {
			withCredentials: true,
		});
		return data;
	} catch (e) {
		return {};
	}
};

function Provider({ children }) {
	const [userState, setUserState] = useState({});

	useEffect(() => {
		parseCookie().then((data) => {
			setUserState(data);
		});
	}, []);

	const logout = () => {
		axios.get(`${process.env.NEXT_PUBLIC_AUTH_API_PATH}/api/v1/user/logout`, {
			withCredentials: true,
		});
		// cookie.set('token', '', { path: '/', expires: new Date(0) });
		// setUserState({});
	};

	/*
	const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
	if (SENTRY_DSN) {
		const {
			_id, user, mail, ...state
		} = userState;
		Sentry.setUser({
			id: _id,
			username: user,
			...state,
		});
	}
    */

	return (
		<ContextStore.Provider
			// eslint-disable-next-line react/jsx-no-constructed-context-values
			value={{
				userState,
				setUserState,
				logout,
			}}
		>
			{ children }
		</ContextStore.Provider>
	);
}

export default dynamic(() => Promise.resolve(Provider), { ssr: false });
export {
	ContextStore,
	parseCookie,
};
