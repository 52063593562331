import axios from 'axios';

const defaultOptions = {
	baseURL: `${process.env.NEXT_PUBLIC_API_PATH}`,
	withCredentials: true,
};

const instance = axios.create(defaultOptions);

instance.interceptors
	.response.use((response) => response, (error) => Promise.reject(error));

// const fetcher = (...args) => fetch(...args).then(res => res.ok && res.json());

const fetcher = (...args) => instance.get(...args)
	.then((res) => res.data)
	.catch((e) => { throw e; });

const fetcherWithHeader = (...args) => instance.get(...args)
	.then((res) => res)
	.catch((e) => { throw e; });

const fetcherWithoutCredentials = (...args) => {
	const ins = axios.create({ withCredentials: false });
	return ins.get(...args)
		.then((res) => res.data)
		.catch((e) => { throw e; });
};

const serversideInstance = instance;
serversideInstance.defaults.baseURL = process.env.API_PATH;
serversideInstance.defaults.headers = {
	'User-Agent': `axios/${axios.VERSION} (BHIS/ODPortal-internal; +https://odportal.tw)`,
};

export default instance;
export {
	serversideInstance,
	fetcher, fetcherWithHeader, fetcherWithoutCredentials, defaultOptions,
};
